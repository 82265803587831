var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block", margin: "4px" } },
    [
      _vm.data
        ? _c("img", {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy",
                value: _vm.data,
                expression: "data"
              }
            ],
            staticStyle: { cursor: "pointer", "object-fit": "contain" },
            attrs: { width: _vm.size, height: _vm.size },
            on: { click: _vm.handlePreview }
          })
        : _c("span", { staticStyle: { "font-style": "italic" } }, [
            _vm._v(" 写真がありません ")
          ]),
      _vm.previewVisible
        ? _c(
            "a-modal",
            {
              attrs: { visible: _vm.previewVisible, footer: null },
              on: { cancel: _vm.handleCancelPreview }
            },
            [
              _c(
                "div",
                { staticClass: "review-wrap" },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.data,
                        expression: "data"
                      }
                    ],
                    staticClass: "mb-sm",
                    style: _vm.cssProps,
                    attrs: { alt: "preview" }
                  }),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        disabled: !_vm.data,
                        type: "link",
                        icon: "download"
                      },
                      on: { click: _vm.downloadLink }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }