//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver';

export default {
  name: 'S3Image',
  props: {
    data: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: Number,
      required: false,
      default: 48
    }
  },
  data() {
    return {
      errorLoaded: false,
      previewVisible: false
    };
  },
  computed: {
    cssProps() {
      return {
        width: '100%',
        height: '380px',
        'object-fit': 'contain'
      };
    }
  },
  methods: {
    handlePreview() {
      this.previewVisible = true;
    },
    handleCancelPreview() {
      this.previewVisible = false;
    },
    downloadLink() {
      const timestamp = new Date().getTime();
      FileSaver.saveAs(`${this.data}?time=${timestamp}`, `univoice_code${timestamp}.bmp`);
    }
  }
};
